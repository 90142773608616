console.log(process.env.REACT_APP_API_URL);
const initialState = {
  api_url: process.env.REACT_APP_API_URL,
  offline: process.env.REACT_APP_OFFLINE,
  django_admin_url: "https://app.urban-experiment.com/admin/",
  test_route: "https://urban-experiment.com/share/",
  maps_api_key: "AIzaSyALTyO6JWZxGua_ofFXLtb3c68XN8U4Qr4"
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state;
  }
};
export default reducer;
