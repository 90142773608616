const initialState = (ui => ({
  success: ui ? JSON.parse(ui).success : ""
}))(localStorage.ui);

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "SHOW_SUCCESS": {
      localStorage.setItem(
        "ui",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("ui")),
          success: action.success
        })
      );
      return {
        ...state,
        error: "",
        success: action.success
      };
    }
    case "REMOVE_SUCCESS": {
      localStorage.setItem(
        "ui",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("ui")),
          success: ""
        })
      );
      return {
        ...state,
        success: ""
      };
    }

    default:
      return state;
  }
};
export default reducer;
