import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import App from "./App.jsx";
import { store, history } from "./store";
import { IntlProviderWrapper } from "IntlContext";
import axios from "axios";

import "typeface-roboto";

import * as serviceWorker from "./serviceWorker";

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
    <IntlProviderWrapper>
      <App />
    </IntlProviderWrapper>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

axios.defaults.headers['Accept-Language'] = localStorage.getItem('language');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
