import React from "react";
import { IntlProvider } from "react-intl";
import messages_es from "./messages.json";
import messages_en from "./messages_en.json";
import messages_it from "./messages_it.json";
import messages_pt from "./messages_pt.json";
import merge from "lodash-es/merge"
import axios from "axios";

import { flattenMessages } from "./helpers/flattenMessages.js";

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);
    const self = this;

    this.switchToEnglish = () =>{
      this.setState({ locale: "en", messages: merge({}, messages_es, messages_en) })
      localStorage.setItem('language','en')
    }

    this.switchToSpanish = () =>{
      this.setState({ locale: "es", messages: messages_es });
      localStorage.setItem('language','es')
    }

    this.switchToItalian = () =>{
      this.setState({ locale: "it", messages: merge({}, messages_es, messages_it) });
      localStorage.setItem('language','it')
    }

    this.switchToPortuguese = () =>{
      this.setState({ locale: "pt", messages: merge({}, messages_es, messages_pt) });
      localStorage.setItem('language','pt')
    }


    this.state = {
      locale: localStorage.getItem('language') == null ? "es": localStorage.getItem('language'),
      messages: localStorage.getItem('language') === "en" ? merge({}, messages_es, messages_en) : localStorage.getItem('language') === "it" ? merge({}, messages_es, messages_it) : localStorage.getItem('language') === "pt" ? merge({}, messages_es, messages_pt) : messages_es,
      switchToEnglish: this.switchToEnglish,
      switchToSpanish: this.switchToSpanish,
      switchToItalian: this.switchToItalian,
      switchToPortuguese: this.switchToPortuguese
    };

    window.addEventListener('changeLanguage', function(e) { 
      if(e.language === 'en') {
        self.switchToEnglish();
      }
      else if(e.language === 'es') {
        self.switchToSpanish();
      }
      else if(e.language === 'it') {
        self.switchToItalian();
      }
      else if(e.language === 'pt') {
        self.switchToPortuguese();
      }
      axios.defaults.headers['Accept-Language'] = localStorage.getItem('language');
    });

  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    axios.defaults.headers['Accept-Language'] = localStorage.getItem('language');
      
    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={flattenMessages(messages)}
          defaultLocale={locale}
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };
